@import "/app/packages/pn-admin/src/stylesheets/data.scss";
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
}

:root {
  --color-white: #ffffff;
  --color-black: #151515;
  --color-grey: #9a9a9a;
  --color-blue: #1e4fbd;
  --color-black-science: #ea3147;
  --color-na-sporte: #5db64c;
  --color-grey-dark: #3b3b3b;
  --color-grey-light: #f0f0f0;
  --color-grey-light-dark: #747474;
  --color-grey-light-dark-op: rgba(116, 116, 116, 0.3);
  --color-blue-light: #dbe7ff;
  --color-blue-light-bg: #f2f6fc;

  --color-postnews-blue: #1e4fbd;
  --color-postnews-yellow: #fddc49;

  --color-social-vk: #0077ff;
  --color-social-telegram: #0088cc;
  --color-social-youtube: #ff0000;
  --color-social-ok: #ed812b;
  --color-social-twitter: #00acee;
  --color-social-whatsapp: #25d366;

  --color-alert: #278a1e;
  --color-fail: #b71f1f;
}

.ant-menu:not(.ant-menu-horizontal) {
  & li.ant-menu-item {
    height: 50px;
  }

  & li.ant-menu-item {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 0;

    &:active {
      color: var(--color-white);

      & .ant-menu-item-icon path {
        fill: var(--color-white);
      }
    }

    &.ant-menu-item-selected {
      color: var(--color-white);

      & .ant-menu-item-icon path {
        fill: var(--color-white);
      }

      & .ant-menu-item-icon path[stroke] {
        stroke: var(--color-black);
        color: transparent;
      }
    }

    & .ant-menu-item-only-child {
      & .ant-menu-title-content {
        padding-left: 28px;
      }
    }
  }

  & li.ant-menu-submenu {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 0;

    & .ant-menu-submenu-title {
      height: 50px;
    }

    & .ant-menu-submenu-arrow {
      display: none;
    }

    & .ant-menu-item-only-child {
      & .ant-menu-title-content {
        padding-left: 28px;
      }
    }
  }

  & ul.ant-menu-sub {
    background-color: transparent;
  }
}

.ant-layout {
  background: #F2F6FC !important;
}

.ant-layout-sider-children {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @include max-width($lg) {
    width: 100% !important;
  }
}

.ant-input-search-button {
  width: 95px !important;
  border-radius: 4px !important;
  height: 44px !important;
}

.ant-modal-confirm-btns {
  text-align: center !important;
}

.ant-radio-group {
  border-radius: 4px !important;
}

.ant-radio-button-wrapper {
  border: 0 !important;
}

.ant-radio-button-wrapper::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper-checked::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper:focus-within {
  box-shadow: none !important;
}

.ant-radio-button-wrapper-checked {
  border-radius: 4px !important;
}

.public-DraftEditorPlaceholder-root {
  font-weight: 500;
  left: 0;
  position: absolute;
  color: var(--color-grey-light-dark);
  pointer-events: none;
}

.public-DraftEditorPlaceholder-hasFocus {
  display: none;
}

.public-DraftStyleDefault-block {
  span {
    span {
      color: var(--color-black);
    }
  }
  a {
    span {
      color: var(--color-postnews-blue);
      text-decoration: underline;
    }
  }
}

.ant-card-actions {
  border-top: 2px solid var(--color-postnews-blue);
}

.ant-dropdown-menu-item {
  padding: 10px 15px;

  & .ant-dropdown-menu-title-content {
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

.pn_notification {
  background: var(--color-black);
  padding: 10px 40px;

  & .ant-notification-notice-close {
    top: 12px;
  }

  & .ant-notification-notice-message {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }

  & span {
    color: var(--color-white);
  }
}

.public-DraftStyleDefault-block {
  margin-bottom: 1em;
}
